import { storage, AccessTokenStorageKey, RefreshTokenStorageKey, ProfileStorageKey, MenusStorageKey,RedirectStorageKey, IsNullOrEmpty } from "./Global";

class Auth {
    signOut() {
        storage.delete(AccessTokenStorageKey);
        storage.delete(RefreshTokenStorageKey);
        storage.delete(ProfileStorageKey);
        storage.delete(MenusStorageKey);
        storage.delete(RedirectStorageKey);
        window.location.href = "/login";
    }

    getAccessToken() {
        let accessToken = storage.read(AccessTokenStorageKey);
        return accessToken;
    }

    getProfile() {
        let profile = storage.read(ProfileStorageKey);
        return profile?profile:{id:0};
    }

    getRefreshToken() {
        let refreshToken = storage.read(RefreshTokenStorageKey);
        return refreshToken;
    }
    hasAccessToken() {
        return this.getAccessToken() != null;
    }                                                                                                  

    GeneratePassword(lengthOfPassword, wantSymbols) {
        var theLetters = "abcdefghijklmnopqrstuvwxyz";
        //Removed "\\", because backslash character can cause problems when passwords are stored.
        var theSymbols = [" ", "!", "\"", "$", "%", "^", "&", "*", "(", ")", "-", "_", "=", "+", "[", "{", "]", "}", ";", ":", "'", "@", "#", "~", "|", ",", "<", ".", ">", "/", "?"];
        var strongPasswordArray = [];
        var capitalise;
        for (var i = 0; i < lengthOfPassword; i++) {
            capitalise = Math.round(Math.random() * 1);
            if (capitalise === 0) {
                strongPasswordArray[i] = theLetters.charAt(Math.floor(Math.random() * 26)).toUpperCase();
            }
            else {
                strongPasswordArray[i] = theLetters.charAt(Math.floor(Math.random() * 26));
            }
        }
        var numberOfDigits;
        numberOfDigits = Math.round(Math.random() * (lengthOfPassword - 1)) + 1;
        var positionForNumeric, theNumber;
        for (i = 0; i < numberOfDigits; i++) {
            positionForNumeric = Math.round(Math.random() * (lengthOfPassword - 1));
            theNumber = Math.round(Math.random() * 9);
            strongPasswordArray[positionForNumeric] = theNumber;
        }
        if (wantSymbols) {
            var numberOfSymbols;
            numberOfSymbols = Math.round(Math.random() * (lengthOfPassword - 1)) + 1;
            var positionForSymbol;
            var locationOfSymbolInArray;
            var theSymbol;
            for (i = 0; i < numberOfSymbols; i++) {
                positionForSymbol = Math.round(Math.random() * (lengthOfPassword - 1));
                locationOfSymbolInArray = Math.round(Math.random() * (theSymbols.length - 1));
                theSymbol = theSymbols[locationOfSymbolInArray];
                strongPasswordArray[positionForSymbol] = theSymbol;
            }
        }
        var strongPassword = "";
        for (i = 0; i < strongPasswordArray.length; i++) {
            strongPassword += strongPasswordArray[i];
        }
        return strongPassword;
    }
}

export default new Auth();