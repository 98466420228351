
export const SideNewsMenu = [
    // {
    //     heading: '简报',
    //     translate: 'sidebar.heading.WeChat News'
    // },

    // {
    //     name: '制作简报',
    //     icon: 'far fa-paper-plane',
    //     translate: 'sidebar.nav.WeChat Found',
    //     path: '/news/found'
    // },
    // {
    //     name: '待发送',
    //     icon: 'far fa-clock',
    //     translate: 'sidebar.nav.Published',
    //     path: '/news/publisheds'
    // },
    // {
    //     name: '已制作',
    //     icon: 'fas fa-paper-plane',
    //     translate: 'sidebar.nav.Published',
    //     path: '/news/published'
    // },

    // {
    //     heading: '设置',
    //     translate: 'sidebar.heading.设置'
    // },


    // {
    //     name: 'Saved Searches & Lists',
    //     icon: 'fab fa-kickstarter-k',
    //     translate: 'sidebar.nav.Saved Searches & Lists',
    //     path: '/educations'
    // },

    // {
    //     name: 'Keywords',
    //     icon: 'fab fa-kickstarter-k',
    //     translate: 'sidebar.nav.Keyword',
    //     path: '/keyword'
    // },
    {
        name: 'Companies',
        icon: 'fas fa-database',
        translate: 'sidebar.nav.Companies & Deals',
        path: '/companyinfos'
    },
    // {
    //     heading: 'Saved Searches & Lists',
    //     translate: 'sidebar.heading.Saved Searches & Lists'
    // },
    // {
    //     name: 'My Searches',
    //     icon: 'fas fa-search',
    //     translate: 'sidebar.nav.My Searches',
    //     path: '/mysearches'
    // },

    // {
    //     name: 'Shared with Me',
    //     icon: 'fas fa-search',
    //     translate: 'sidebar.nav.Shared with Me',
    //     path: '/sharedwithme'
    // },
    {
        heading: 'Settings',
        translate: 'sidebar.heading.Settings'
    }, {
        name: 'Top Companies',
        icon: 'fas fa-globe',
        translate: 'sidebar.nav.TopCompanies',
        path: '/companies'
    },
    {
        name: 'Senior Management',
        icon: 'far fa-id-card',
        translate: 'sidebar.nav.SeniorManagement',
        path: '/positions'
    },

    {
        name: 'Top Universities',
        icon: 'far fa-hospital',
        translate: 'sidebar.nav.TopUniversities',
        path: '/universities'
    },
    {
        name: 'Degrees',
        icon: 'fas fa-graduation-cap',
        translate: 'sidebar.nav.Degrees',
        path: '/educations'
    }, {
        name: 'Keywords',
        icon: 'fab fa-kickstarter-k',
        translate: 'sidebar.nav.Keywords',
        path: '/keywords'
    },
    {
        name: 'Other',
        icon: 'fab fa-whmcs',
        translate: 'sidebar.nav.Setting',
        path: '/other'
    },
    // {
    //     name: 'icon',
    //     icon: 'fas fa-paper-plane',
    //     translate: 'sidebar.nav.Setting',
    //     path: '/fonticons'
    // },

];
export const SideWeChatMenu = [

    // {
    //     heading: '微信群',
    //     translate: 'sidebar.heading.群列表'
    // },
    // {
    //     name: '踢人出群',
    //     icon: 'icon-people',
    //     translate: 'sidebar.nav.WeChat Rooms',
    //     path: '/news/rooms'
    // },
    // {
    //     name: '邀人入群',
    //     icon: 'fas fa-user-plus',
    //     translate: 'sidebar.nav.邀人入群',
    //     path: '/news/addrooms'
    // },
    // {
    //     name: '踢人权限',
    //     icon: 'fas fa-user-minus',
    //     translate: 'sidebar.nav.踢人出群',
    //     path: '/news/kickingauthority'
    // },
    // {
    //     heading: '黑名单',
    //     translate: 'sidebar.heading.黑名单'
    // },
    // {
    //     name: '恶意用户',
    //     icon: 'fas fa-user-alt-slash',
    //     translate: 'sidebar.nav.恶意用户',
    //     path: '/news/blacklists'
    // },
    // {
    //     name: '禁用词',
    //     icon: 'fab fa-kickstarter-k',
    //     translate: 'sidebar.nav.禁用词',
    //     path: '/news/bannedkeyords'
    // },
    // {
    //     heading: '消息',
    //     translate: 'sidebar.heading.消息'
    // },
    // {
    //     name: '新人入群',
    //     icon: 'far fa-smile',
    //     translate: 'sidebar.nav.Groups',
    //     path: '/news/welcome'
    // },
    // {
    //     name: '自动回复',
    //     icon: 'icon-bubbles',
    //     translate: 'sidebar.nav.Groups',
    //     path: '/wxautoreplys'
    // },
    // {
    //     name: '定时群发',
    //     icon: 'far fa-clock',
    //     translate: 'sidebar.nav.Groups',
    //     path: '/news/scheduler'
    // },
    // {
    //     heading: '好友',
    //     translate: 'sidebar.heading.设置'
    // },

    // {
    //     name: '新好友',
    //     icon: 'fab fa-whmcs',
    //     translate: 'sidebar.nav.功能设置',
    //     path: '/news/setting'
    // },
    // {
    //     heading: '词频',
    //     translate: 'sidebar.heading.词频'
    // },
    // {
    //     name: '词性',
    //     icon: 'fab fa-tags',
    //     translate: 'sidebar.nav.词性',
    //     path: '/termtags'
    // },
    // {
    //     name: '行业词库/专业词汇',
    //     icon: 'fab fa-tags',
    //     translate: 'sidebar.nav.行业词库/专业词汇',
    //     path: '/terms'
    // },
    // {
    //     name: '非行业词库',
    //     icon: 'fab fa-tags',
    //     translate: 'sidebar.nav.非行业词库',
    //     path: '/excludedterms'
    // },
    // {
    //     name: '同义词',
    //     icon: 'fab fa-tags',
    //     translate: 'sidebar.nav.同义词',
    //     path: '/synonyms'
    // },
];