import { Store } from 'laco'
import { useState, useEffect } from 'react';

export const CommentStore = new Store(
  {
    /**
     * 机器人菜单是否显示
     */
    showBotMenu: true,
    /**
     * 马甲菜单是否显示
     */
    showPuppetMenu: false,
    showSendNewsMenu: false,
    showWeChatMenu: false,
  },
  'CommentStore'
)

export function useStore() {
  const [state, setState] = useState(CommentStore.get())

  function updateState() {
    setState(CommentStore.get())
  }

  useEffect(() => {
    CommentStore.subscribe(updateState)
    return () => CommentStore.unsubscribe(updateState)
  })

  return state
}

/**
 * 获取机器人菜单是否显示
 */
export const getshowBotMenu = () => CommentStore.get().showBotMenu

/**
 * 改变机器人菜单是否显示
 */
export const setshowBotMenu = showBotMenu => {
  if (typeof showBotMenu !== 'boolean' || showBotMenu === CommentStore.get().showBotMenu) return;

  CommentStore.set(
    () => ({
      showBotMenu: showBotMenu
    }),
    'Set showBotMenu'
  )
  console.log('showBotMenu=', showBotMenu);
}
/**
 * 获取马甲菜单是否显示
 */
export const getshowPuppetMenu = () => CommentStore.get().showPuppetMenu

/**
 * 改变马甲菜单是否显示
 */
export const setshowPuppetMenu = showPuppetMenu => {
  if (typeof showPuppetMenu !== 'boolean' || showPuppetMenu === CommentStore.get().showPuppetMenu) return;

  CommentStore.set(
    () => ({
      showPuppetMenu: showPuppetMenu
    }),
    'Set showPuppetMenu'
  )
  console.log('showPuppetMenu=', showPuppetMenu);

}

/**
 * 获取新闻菜单是否显示
 */
export const getshowSendNewsMenu = () => CommentStore.get().showSendNewsMenu

/**
 * 改变新闻菜单是否显示
 */
export const setshowSendNewsMenu = showSendNewsMenu => {
  if (typeof showSendNewsMenu !== 'boolean' || showSendNewsMenu === CommentStore.get().showSendNewsMenu) return;

  CommentStore.set(
    () => ({
      showSendNewsMenu: showSendNewsMenu
    }),
    'Set showSendNewsMenu'
  )
  console.log('showSendNewsMenu=', showSendNewsMenu);

}

/**
 * 获取微信是否显示
 */
export const getshowWeChatMenu = () => CommentStore.get().showWeChatMenu

/**
 * 改变微信菜单是否显示
 */
export const setshowWeChatMenu = showWeChatMenu => {
  if (typeof showWeChatMenu !== 'boolean' || showWeChatMenu === CommentStore.get().showWeChatMenu) return;

  CommentStore.set(
    () => ({
      showWeChatMenu: showWeChatMenu
    }),
    'Set showWeChatMenu'
  )
  console.log('showWeChatMenu=', showWeChatMenu);

}
