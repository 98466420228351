import $ from 'jquery';
import Auth from './Auth';
import DeviceStorage from 'react-device-storage';
/**
 * Used as a global variable
 * 
 * @copyright 2018 Tao Song <fuwu@fagao.org.cn>
 *
 * @author: 宋涛
 *
 */

/**
 * 客户ID
 * 
 * @example
 * import { storage } from "./Global";
 * 
 */
export const storage = new DeviceStorage({
    cookieFallback: true,
    cookie: {
      secure: true
    }
}).localStorage();

/**
 * 客户ID
 * 
 * @example
 * import { ClientId } from "./Global";
 * 
 */
export const ClientId = `demo`;

/**
 * 路由保存在localStorage中的key
 * 
 * @example
 * import { RedirectStorageKey } from "./Global";
 * 
 */
export const RedirectStorageKey = `redirect`;
/**
 * access token保存在localStorage中的key
 * 
 * @example
 * import { AccessTokenStorageKey } from "./Global";
 * 
 */
export const AccessTokenStorageKey = `access_token`;

/**
 * refresh token保存在localStorage中的key
 * 
 * @example
 * import { RefreshTokenStorageKey } from "./Global";
 * 
 */
export const RefreshTokenStorageKey = `refresh_token`;

/**
 * settings保存在localStorage中的key
 * 
 * @example
 * import { SettingsStorageKey } from "./Global";
 * 
 */
export const SettingsStorageKey = `settings`;

/**
 * profile保存在localStorage中的key
 * 
 * @example
 * import { ProfileStorageKey } from "./Global";
 * 
 */
export const ProfileStorageKey = `profile`;
/**
 * 菜单保存在localStorage中的key
 * 
 * @example
 * import { HeaderMenuStorageKey } from "./Global";
 * 
 */
export const HeaderMenuStorageKey = `HeaderMenuStorageKey`;

/**
 * menus保存在localStorage中的key
 * 
 * @example
 * import { MenusStorageKey } from "./Global";
 * 
 */
export const MenusStorageKey = `menus`;

/**
 * 判断手机号码的正则表达式
 * 
 * @example
 * import {mobilePhoneRegex} from "./Global";
 * 
 */
export const mobilePhoneRegex = /^1[3|4|5|7|8][0-9]\d{8}$/;
// export const mobilePhoneRegex = /(?=\d{11})1(?:3\d|4[57]|5[^4\D]|66|7[^249\D]|8\d|9[89])\d{8}/;

/**
 * 判断QQ号码的正则表达式
 * 
 * @example
 * import {qqRegex} from "../Global";
 * 
 */
export const qqRegex = /^[1-9][0-9]{4,}$/;

/**
 * 判断电子邮箱的正则表达式
 * 
 * @example
 * import {emailRegex} from "./Global";
 * 
 */
export const emailRegex = /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,6}$/i;
/**
 * 判断url的正则表达式
 * 
 * @example
 * import {urlRegex} from "./Global";
 * 
 */
export const urlRegex = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;

/**
 * 判断对象是否为空
 * 参考：https://www.cnblogs.com/kissdodog/p/3386480.html
 * https://codereview.stackexchange.com/questions/5572/string-isnullorempty-in-javascript
 * @returns {boolean}
 * 
 * @example
 * import {IsNullOrEmpty} from "../Global";
 * if(IsNullOrEmpty(res)) { }
*/
/**
 * 判断对象是否为网址
 */
export function IsUrl(s) {
    if (s===null || s===undefined || typeof (s)==='undefined' || typeof (s) !== 'string' || s==='') return false;
    let r = /^(https?|s?ftp|git):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;
    return r.test(s);
};

// export const emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
/**
 * 登录帐号的样式
 * 
 * @example
 * import {loginStyles} from "../Global";
 * import default from './components/Extras/Search.run';
 */
export const loginStyles = {    
    default: 'fa fa-user text-muted bg-transparent border-left-0',
    // default: ' mr-2 fab fa-qq bg-transparent border-left-0 text-muted',
    wechat: 'fa fa-wechat bg-transparent border-left-0 text-info',
    mobilephone: 'fa fa-mobile-phone bg-transparent border-left-0 text-info',
    qq: ' mr-2 fab fa-qq bg-transparent border-left-0 text-info',
    email: 'fa fa-envelope bg-transparent border-left-0 text-info',
    password: 'fa fa-lock text-muted bg-transparent border-left-0'
};

/**
 * 日期格式化
 * 
 * @example
 * import {DateFormatString} from "../Global";
 * 
 */
export const DateFormatString = "yyyy-MM-dd HH:mm:ss";
/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
 * 例子：
 * "yyyy-MM-dd hh:mm:ss.S" ==> 2006-07-02 08:09:04.423
 * "yyyy-M-d h:m:s.S" ==> 2006-7-2 8:9:4.18
 * @param {Date} dateString
 * @returns {string}
 * 
 * @example
 * import {FormatDate} from "./Global";
 * FormatDate(dateString);
*/
export function FormatDateLong(dateString) {
    if(!dateString) return "";
    var d = new Date(dateString);
    var pattern="yyyy-MM-dd HH:mm:ss"; //  HH:mm:ss

    var o = {
        "M+": d.getMonth() + 1, //月份 
        "d+": d.getDate(), //日 
        "H+": d.getHours(), //小时
        "m+": d.getMinutes(), //分 
        "s+": d.getSeconds(), //秒 
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度 
        "S": d.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(pattern)) pattern = pattern.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(pattern)) pattern = pattern.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return pattern;
};

/**
 * 对Date的扩展，将 Date 转化为指定格式的String
 * 月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符
 * 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字) 
 * 例子：
 * "yyyy-MM-dd hh:mm:ss.S" ==> 2006-07-02 08:09:04.423
 * "yyyy-M-d h:m:s.S" ==> 2006-7-2 8:9:4.18
 * @param {Date} dateString
 * @returns {string}
 * 
 * @example
 * import {FormatDate} from "./Global";
 * FormatDate(dateString);
*/
export function FormatDate(dateString) {
    if(!dateString) return "";
    var d = new Date(dateString);
    var pattern="yyyy-MM-dd"; //  HH:mm:ss

    var o = {
        "M+": d.getMonth() + 1, //月份 
        "d+": d.getDate(), //日 
        "H+": d.getHours(), //小时
        "m+": d.getMinutes(), //分 
        "s+": d.getSeconds(), //秒 
        "q+": Math.floor((d.getMonth() + 3) / 3), //季度 
        "S": d.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(pattern)) pattern = pattern.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(pattern)) pattern = pattern.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return pattern;
};

/**
 * 解析网址里的查询字符串QueryString
 * @returns {json}
 * 
 * @example
 * import {QueryString} from "./Global";
 * var qs = QueryString();
 * var args = { FirstName: qs.firstname, LastName: qs.lastname };
*/
export function QueryString() {
    var args = {};
    var query = window.location.search.substring(1);
    var pairs = query.split("&");
    for (var i = 0; i < pairs.length; i++) {
        var pos = pairs[i].indexOf("=");
        if (pos === -1) {
            continue;
        }
        var name = pairs[i].substring(0, pos);
        console.log(name);
        var value = pairs[i].substring(pos + 1);
        args[name] = value;
    }
    return args;
};

/**
 * AJAX GET请求
 * 
 * @example
 * import {Get} from "./Global";
 * Get('url', function (res) {
 * 
 * }.bind(this));
*/
export function Get(url, callback) {
    return $.ajax({
        type: 'GET',
        url: url,
        headers: { Authorization: "Bearer " + Auth.getAccessToken() },
        dataType: 'json',
        async:false,
        cache: false,
        // contentType: "application/json; charset=utf-8",
        success: function(res) {
            console.log(url);
            console.log(res);
            if(res.r === '401' || res.r==='404')
            {
                alert(res.m);
                Auth.signOut();
                return;
            }

            if(callback) callback(res);
        },
        error: function(xhr) {
            // 身份认证过期或未登录
            if (xhr.status === 401) {
                Auth.signOut();
            }
            
            if(callback) callback({r:'503',m:'The server is busy. Please try again later.'});
        }
    });
};

/**
 * AJAX POST JSON
 * 
 * @example
 * import {Post} from "./Global";
 * Post('url', {}, function (res) { 
 * 
 * }.bind(this));
*/
export function Post(url, json, callback) {
    return $.ajax({
        type: 'POST',
        url: url,
        headers: { Authorization: "Bearer " + Auth.getAccessToken() },
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        data: JSON.stringify(json),
        success: function(res) {
            console.log(url);
            console.log(res);
            if(res.r === '401' || res.r==='404')
            {
                alert(res.m);
                Auth.signOut();
                return;
            }

            callback && callback(res);
        },
        error: function(xhr) {
            console.log(JSON.stringify(xhr));
            // 身份认证过期或未登录
            if (xhr.status === 401) {
                Auth.signOut();
            }
            callback && callback({r:'503',m:'The server is busy. Please try again later.'});
        }
    });
};

/**
 * AJAX POST Form
 * 
 * @example
 * import {PostForm} from "./Global";
 * PostForm('url', {}, function (res) { 
 * 
 * }.bind(this));
*/
export function PostForm(url, formData, callback) {
    return $.ajax({
        type: 'POST',
        url: url,
        headers: { Authorization: "Bearer " + Auth.getAccessToken() },
        contentType: 'application/x-www-form-urlencoded;charset=utf-8',
        data: formData,
        success: function(res) {
            console.log(url);
            console.log(res);
            if(res.r === '401' || res.r==='404')
            {
                alert(res.m);
                Auth.signOut();
                return;
            }

            callback && callback(res);
        },
        error: function(xhr) {
            console.log(JSON.stringify(xhr));
            // 身份认证过期或未登录
            if (xhr.status === 401) {
                Auth.signOut();
            }
            callback && callback({r:'503',m:'The server is busy. Please try again later.'});
        }
    });
};
/**
 * AJAX POST Form
 * 
 * @example
 * import {Delete} from "./Global";
 * Delete('url', {}, function (res) { 
 * 
 * }.bind(this));
*/
export function Delete(url, formData, callback) {   
    return $.ajax({
        type: 'DELETE',
        url: url,
        headers: { Authorization: "Bearer " + Auth.getAccessToken() },
        contentType: 'application/json;charset=utf-8',
        data: JSON.stringify(formData),
        dataType:"json",
        success: function(res) {
            console.log(url);
            console.log(res);
            if(res.r === '401' || res.r==='404')
            {
                alert(res.m);
                Auth.signOut();
                return;
            }

            callback && callback(res);
        },
        error: function(xhr) {
            console.log(JSON.stringify(xhr));
            // 身份认证过期或未登录
            if (xhr.status === 401) {
                Auth.signOut();
            }
            callback && callback({r:'503',m:'The server is busy. Please try again later.'});
        }
    });
};

/**
 * AJAX POST上传文件
 * @returns {json}
 * 
 * @example
 * import {Upload} from "./Global";
 * Upload('url', {}, function (res) { 
 * 
 * }.bind(this));
*/
export function Upload(url, data, callback) {
    return $.ajax({
        type: 'POST',
        url: url,
        headers: { Authorization: "Bearer " + Auth.getAccessToken() },
        dataType: 'json', // multipart/form-data
        cache: false,
        data: data,
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
        success: function(res) {
            console.log(url);
            console.log(res);
            if(res.r === '401')
            {
                Auth.signOut();
            }
            else
            {
                callback && callback(res);
            }            
        },
        error: function(xhr) {
            // 身份认证过期或未登录
            if (xhr.status === 401) {
                Auth.signOut();
            }
            callback && callback({r:'503',m:'The server is busy. Please try again later.'});
        }
    });
};

/**
 * 判断对象是否为空
 * 参考：https://www.cnblogs.com/kissdodog/p/3386480.html
 * https://codereview.stackexchange.com/questions/5572/string-isnullorempty-in-javascript
 * @returns {boolean}
 * 
 * @example
 * import {IsNullOrEmpty} from "../Global";
 * if(IsNullOrEmpty(res)) { }
*/
export function IsNullOrEmpty(s) {
    if (s===null || s===undefined || typeof (s)==='undefined' || s==='') return true;
    
    if (typeof (s) === 'string') {
        s=s.trim();
        if (s==='') return true;
    }

    return false;
};

/**
 * 格式化时间差
 * @returns {string}
 * 
 * @example
 * import {FormatTimeSpan} from "../Global";
 * let f = FormatTimeSpan(d)
*/
export function FormatTimeSpan(timeSpan) {
    if (timeSpan===null || timeSpan===undefined || typeof (timeSpan)==='undefined' || typeof (timeSpan) !== 'number' || isNaN(timeSpan)) return '';

    //计算出相差天数
    var days=Math.floor(timeSpan/(24*3600*1000));

    //计算出小时数
    var leave1=timeSpan%(24*3600*1000);  //计算天数后剩余的毫秒数
    var hours=Math.floor(leave1/(3600*1000));

    //计算相差分钟数
    var leave2=leave1%(3600*1000);       //计算小时数后剩余的毫秒数
    var minutes=Math.floor(leave2/(60*1000));

    //计算相差秒数
    var leave3=leave2%(60*1000);      //计算分钟数后剩余的毫秒数
    var seconds=Math.round(leave3/1000);

    return ((days===0)?'':(days+'天 ')) + ((hours===0)?'':(hours+"小时 ")) + ((minutes===0)?'':(minutes+' 分钟')) + seconds+" 秒";
};

/**
 * 基本队列
 * 参考：https://www.cnblogs.com/dee0912/p/4960025.html
 * 
 * @example
 * import {Queue} from "../Global";
 * var queue = new Queue();
 * queue.enqueue('hello');
 * var item=queue.dequeue();
*/
export function Queue() {
    /**
     * 初始化队列（使用数组实现）
     * 
     * @example
     * var queue = new Queue();
     * queue.enqueue('hello');
     * queue.dequeue();
    */
    var items = [];

    /**
     * 向队列尾部插入元素
     * 
     * @example
     * var queue = new Queue();
     * queue.enqueue('hello');
     * queue.enqueue('world');
    */
   this.enqueue = function(element) {
        items.push(element);
    }

    /**
     * 从队列头部读取一个元素，并返回该元素
     * 
     * @example
     * console.log(queue.dequeue());   //hello
     * console.log(queue.dequeue());   //world
    */
    this.dequeue = function() {
        return items.shift();
    }

    /**
     * 清空队列中所有元素
     * 
     * @example
     * queue.clear();
    */
    this.clear = function() {
        let item=items.shift();
        while (item)
        {
            item=items.shift();
        }
    }

    /**
     * 查看队列最前面的元素（数组中索引为0的元素）
     * 
     * @example
     * console.log(queue.front());      //hello
     * console.log(queue.front());     // undefined
    */
    this.front = function() {
        return items[0];
    }

    /**
     * 查看队列是否为空，如果为空，返回true；否则返回false
     * 
     * @example
     * console.log(queue.isEmpty());   //false
     * console.log(queue.isEmpty());   //true
    */
    this.isEmpty = function() {
        return items.length === 0;
    }

    /**
     * 查看队列的长度
     * 
     * @example
     * console.log(queue.size());      //2
     * console.log(queue.size());      //0
    */
    this.size = function() {
        return items.length;
    }

    /**
     * 打印队列
     * 
     * @example
     * console.log(queue.print());     //hello,world
     * console.log(queue.print());     //(空字符串)
    */
    this.print = function() {
        //以字符串形势返回
        return items.toString();
    }
};

/**
 * Simplify error check
 * @returns {Boolean}
 * 
 * @example
 * import {HasValidateError} from "../Global";
 * let error = HasValidateError(this.state['formName'], inputName, method)
*/
export function HasValidateError (errors, inputName, method) {
    return  errors &&
            errors[inputName] &&
            errors[inputName][method]
}