// https://github.com/chriso/validator.js
import validator from 'validator';
/**
 * Helper methods to validate form inputs
 * using controlled components
 */
const FormValidator = {
    /**
     * Validate input element
     * @param element Dome element of the input
     * Uses the following attributes
     *     data-validate: array in json format with validation methods
     *     data-param: used to provide arguments for certain methods.
     */
    validate(element) {

        const isCheckbox = element.type === 'checkbox';
        const value = isCheckbox ? element.checked : element.value;
        const name = element.name;

        // if (!name) throw new Error('Input name must not be empty.');
        if (!name) return;
        // use getAttribute to support IE10+
        const param = element.getAttribute('data-param');
        const validations = JSON.parse(element.getAttribute('data-validate'));

        let result = []
        if(validations && validations.length) {
            /*  Result of each validation must be true if the input is invalid
                and false if valid. */
            validations.forEach(m => {
                switch (m) {
                    case 'required':
                        result[m] = isCheckbox ? value === false : validator.isEmpty(value)
                        console.log(`result[m]`,result[m]);
                        break;
                    case 'username':
                        // 用户名允许中文、数字、字母和部分标点符号，最少2个字符，最多64字符
                        var username = /^[\u4e00-\u9fa50-9a-zA-Z.@\-_]{2,64}$/;
                        result[m] = !username.test(value)
                        break;
                    case 'qq':
                        // 判断字符串是否为QQ号码
                        var qq = /^[1-9][0-9]{4,}$/;
                        result[m] = !qq.test(value);
                        break;
                    case 'mobile':
                        // 判断字符串是否为手机号码
                        var mobile = /^1[3|4|5|7|8][0-9]\d{8}$/;
                        result[m] = !mobile.test(value);
                        break;
                    case 'tell':
                        // 判断字符串是否为手机号码                        
                        if(value)
                        {
                            var regex = /(^(\d{3,4}-)?\d{7,8})$|(1[3|5|7|8]\d{9})/;
                            console.log(`regex.test('${value}') = `, regex.test(value));
                            result[m] = !regex.test(value)
                            console.log(`result[m]`,result[m]);
                        }
                        else
                        {
                            result[m] = true
                        }
                        // var tell = /(^(\d{3,4}-)?\d{7,8})$|(13[0-9]{9})/;
                        // result[m] = !tell.test(value);
                        
                        break;
                    case 'email':
                        // 判断电子邮箱的正则表达式
                        // /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2,6}$/i;
                        result[m] = !validator.isEmail(value)
                        break;
                    case 'mqe':
                        var q = /^[1-9][0-9]{4,}$/;
                        var mo = /^1[3|4|5|7|8][0-9]\d{8}$/;
                        result[m] = !(validator.isEmail(value)||q.test(value)||mo.test(value))
                        break;
                    case 'number':
                        result[m] = !validator.isNumeric(value)
                        break;
                    case 'integer':
                        result[m] = !validator.isInt(value)
                        break;
                    case 'alphanum':
                        result[m] = !validator.isAlphanumeric(value)
                        break;
                    case 'url':
                        result[m] = !validator.isURL(value)
                        break;
                    case 'equalto':
                        // here we expect a valid ID as param
                        const value2 = document.getElementById(param).value;
                        result[m] = !validator.equals(value, value2)
                        break;
                    case 'minlen':
                        result[m] = !validator.isLength(value, { min: param })
                        break;
                    case 'maxlen':
                        result[m] = !validator.isLength(value, { max: param })
                        break;
                    case 'len':
                        const [min, max] = JSON.parse(param)
                        result[m] = !validator.isLength(value, { min, max })
                        break;
                    case 'min':
                        result[m] = !validator.isInt(value, { min: validator.toInt(param) })
                        break;
                    case 'max':
                        result[m] = !validator.isInt(value, { max: validator.toInt(param) })
                        break;
                    case 'between': 
                        const [minNum, maxNum] = JSON.parse(param)
                        result[m] = !(value>minNum&&value<=maxNum)
                        break;
                    case 'list':
                        const list = JSON.parse(param)
                        result[m] = !validator.isIn(value, list)
                        break;
                    default:
                        throw new Error('Unrecognized validator.');
                }

            })
        }

        return result;
    },

    /**
     * Bulk validation of input elements.
     * Used with form elements collection.
     * @param  {Array} inputs Array for DOM element
     * @return {Object}       Contains array of error and a flag to
     *                        indicate if there was a validation error
     */
    bulkValidate(inputs) {
        let errors = {},
            hasError = false;

        inputs.forEach(input => {
            let result = this.validate(input)
            if(!result) return false;
            errors = { 
                ...errors, 
                [input.name]: result }
            if (!hasError) hasError = Object.keys(result).some(val => result[val])
        })

        return {
            errors,
            hasError
        }
    }
}

export default FormValidator;