import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { storage, ClientId, RefreshTokenStorageKey, IsNullOrEmpty, ProfileStorageKey, HeaderMenuStorageKey } from "../../Global"; // ,Queue
import { setshowBotMenu, setshowPuppetMenu, setshowSendNewsMenu, setshowWeChatMenu } from '../../store';
import { UncontrolledDropdown } from 'reactstrap';

import Auth from '../../Auth';
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import HeaderRun from './Header.run'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditPasswordModel from '../../EditPasswordModel';
class Header extends Component {


    constructor(props) {
        super(props);

        this.state = {
            // ShowMessageModal: false,
            Profile: {},//私聊消息对象
            PhoneNumber: '',
            DriverPhoneNumber: '',
            ShowEditPasswordModel: false,
            ShowAuthCodeModel: false,
            ShowDriverCodeModel: false,
            Connection: null,
            ShowJb: 1,
            ShowWx: 2
        };

        this.handleLogout = this.handleLogout.bind(this);
        this.handleEditPasswordToggle = this.handleEditPasswordToggle.bind(this);
    }
    componentDidMount() {
        HeaderRun();
        // 加载顶部菜单
        var headerMenu = storage.read(HeaderMenuStorageKey);
        if (IsNullOrEmpty(headerMenu)) {
            storage.save(HeaderMenuStorageKey, 'SendNewsMenu');
        }
        // 加载用户信息菜单
        var profileStorage = storage.read(ProfileStorageKey);
        if (!IsNullOrEmpty(profileStorage)) {
            this.setState({
                Profile: profileStorage
            });
        }
        console.log(profileStorage);
    }
    

    // 修改密码
    handleEditPassword = e => {
        e.preventDefault()
        console.log(123123);
        this.setState({ ShowEditPasswordModel: true });
    }
    // 关闭修改密码
    handleEditPasswordToggle(message, color) {
        this.setState({ ShowEditPasswordModel: false });// 隐藏弹窗
        if (!IsNullOrEmpty(message)) {
            toast(message, { type: color, position: 'bottom-right' });
        }
    }
    toggleCollapsed = e => {
        e.preventDefault()
        // this.props.actions.toggleSetting('hiddenFooter');
        this.props.actions.toggleSetting('isCollapsed');
        // this.props.actions.toggleSetting('asideToggled');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }
    showSendNewsMenu = e => {
        e.preventDefault()
        storage.save(HeaderMenuStorageKey, 'SendNewsMenu');
        setshowBotMenu(false);
        setshowPuppetMenu(false);
        setshowSendNewsMenu(true)
        setshowWeChatMenu(false)
        this.resize()
    }

    showWeChatMenu = e => {
        e.preventDefault()
        storage.save(HeaderMenuStorageKey, 'WeChatMenu');
        setshowBotMenu(false);
        setshowPuppetMenu(false);
        setshowSendNewsMenu(false)
        setshowWeChatMenu(true)
        this.resize()
    }


    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }
    handleLogout(e) {
        let postData = {
            refreshToken: storage.read(RefreshTokenStorageKey)
        };
        axios.post(`/api/auth/Logout?clientid=${ClientId}&t=${new Date().getTime()}`, postData)
            .then(function () {
                Auth.signOut();
            }).catch(function (error) {
                console.log(error);
                this.setState({ Alert: { Visible: true, Message: 'The server is busy. Please try again later.', Color: "danger" } });
            }.bind(this));
    }
    render() {
        let showMenu = storage.read(HeaderMenuStorageKey);
        return (
            <header className="topnavbar-wrapper">
                <ToastContainer />
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo text-white">
                                <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */}

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            <a href="" className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                        {/* <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret" onClick={this.showBotMenu}>
                                <i title='Is Bot' className="fas fa-robot mr-1"></i>Bot
                            </DropdownToggle>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret" onClick={this.showPuppetMenu}>
                                <i className="fas fa-user-astronaut mr-1"></i>  Puppet
                            </DropdownToggle>
                        </UncontrolledDropdown> */}
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            {/* <DropdownToggle nav className="dropdown-toggle-nocaret "
                                onClick={this.showSendNewsMenu}>
                                <i className="fab fa-telegram mr-1"></i>  简报
                            </DropdownToggle>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret" onClick={this.showWeChatMenu}>
                                <i className="fab fa-weixin mr-1"></i>  微信
                            </DropdownToggle> */}
                        </UncontrolledDropdown>
                    </ul>
                    { /* END Left navbar */}
                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">
                        { /* START 登录帐号 */}
                        <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.Name)}>
                            <span className="nav-link">{this.state.Profile.Name}</span>
                        </li>
                        { /* END 登录帐号 */}
                        { /* START Logout button */}
                        <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.Name)}>
                            <a title="Change Password" className="nav-link" onClick={this.handleEditPassword.bind(this)}>
                            Set Password
                            </a>
                        </li>
                        <li className="nav-item" hidden={IsNullOrEmpty(this.state.Profile.Name)}>
                            <a title="Out" className="nav-link" onClick={this.handleLogout.bind(this)}>
                                {/* <em className="icon-logout"></em> */}Logout
                            </a>
                        </li>
                        { /* END Logout menu */}
                        { /* START login button */}
                        <li className="nav-item" hidden={!IsNullOrEmpty(this.state.Profile.Name)}>
                            <a title="Login" className="nav-link mr-2" href='/login'>
                                {/* <em className="icon-login"></em> */}Login
                            </a>
                        </li>
                        { /* END login menu */}
                    </ul>
                    { /* END Right Navbar */}

                </nav>
                <EditPasswordModel Show={this.state.ShowEditPasswordModel} Toggle={this.handleEditPasswordToggle} />
                { /* END Top Navbar */}
            </header>
        );
    }

}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);