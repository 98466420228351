import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

// App Routes
import Routes from './Routes';
//axios 请求拦截器
import axios from 'axios';
import Auth from './Auth';
// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'



class App extends Component {
  render() {
    axios.interceptors.request.use(function (config) {    // 这里的config包含每次请求的内容
      let token = Auth.getAccessToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }, function (err) {
      return Promise.reject(err);
    })
    // axio 拦截器 
    axios.interceptors.response.use(function (response) {
      // http请求成功但是返回值提示401错误
      if (response.data.R === '401') {
        Auth.signOut();
      }
      return response;
    }, function (error) {
      console.log(error);
      // http请求状态码是401
      if (error.response.status === 401) {
        Auth.signOut();
      }
      return Promise.reject(error);
    });
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );

  }
}

export default App;
