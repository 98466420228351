import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props} />;

const Login = lazy(() => import('./Login'));
const Register = lazy(() => import('./Register'));
const FontIcons = lazy(() => import('./components/Elements/FontIcons'));
const WeatherIcons = lazy(() => import('./components/Elements/WeatherIcons'));
const TableStandard = lazy(() => import('./components/Tables/TableStandard'));
const DataGrid = lazy(() => import('./components/Tables/DataGrid'));
const Datatable = lazy(() => import('./components/Tables/Datatable'));
// 微信
// const NewsWechatHeader = lazy(() => import('./admin/NewsWechatHeader'));
// const NewsWechatFooter = lazy(() => import('./admin/NewsWechatFooter'));
// const NewsWechatPublished = lazy(() => import('./admin/NewsWechatPublished'));
// const NewsWechatFound = lazy(() => import('./admin/NewsWechatFound'));
// const NewsPosts = lazy(() => import('./admin/Posts'));
// const NewsWechatGroups = lazy(() => import('./admin/NewsWechatGroups'));
// const NewswxWelComes = lazy(() => import('./admin/NewswxWelComes')); //新人入群
// const NewswxBannedKeyords = lazy(() => import('./admin/NewswxBannedKeyords')); //禁用词
// const NewswxKickings = lazy(() => import('./admin/NewswxKickings')); //禁用词

// const WechatRooms = lazy(() => import('./admin/WechatRooms')); //微信群 Rooms
// const WechatBlacklists = lazy(() => import('./admin/WechatBlacklists')); //微信黑名单
// const WechatMembers = lazy(() => import('./admin/WechatMembers')); //微信群成员



const Companies = lazy(() => import('./admin/Companies'));
const Keywords = lazy(() => import('./admin/Keywords'));
const TopUniversities = lazy(() => import('./admin/TopUniversities'));
const Educations = lazy(() => import('./admin/Educations'));
const Positions = lazy(() => import('./admin/Positions'));
// const Deals = lazy(() => import('./admin/Deals'));
const CompanyInfos = lazy(() => import('./admin/CompanyInfos'));
const Mysearches = lazy(() => import('./admin/Mysearches'));
const SharedwithMe = lazy(() => import('./admin/SharedwithMe'));
const Other = lazy(() => import('./admin/Setting'));

// const WechatAutoReplys = lazy(() => import('./admin/WechatAutoReplys')); //微信自动回复
// const NewswxAddRooms = lazy(() => import('./admin/NewswxAddRooms')); //邀人入群
// const NewswxScheduler = lazy(() => import('./admin/NewswxScheduler')); //定时群发
// const TermTags = lazy(() => import('./admin/TermTags')); //词性
// const Terms = lazy(() => import('./admin/Terms')); //行业词库/专业词汇
// const ExcludedTerms = lazy(() => import('./admin/ExcludedTerms')); //非行业词库
// const Synonyms = lazy(() => import('./admin/Synonyms')); //同义词

const Loading = lazy(() => import('./Loading')); //中转页面

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
    '/loading',
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    const animationName = 'rag-fadeIn'

    if (listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                        <Route path="/login" component={waitFor(Login)} />
                        <Route path="/register" component={waitFor(Register)} />
                        <Route path="/loading" component={waitFor(Loading)} />
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>

                                <Switch location={location}>
                                    <Route path="/icons-font" component={waitFor(FontIcons)} />
                                    <Route path="/icons-weather" component={waitFor(WeatherIcons)} />
                                    <Route path="/DataGrid" component={waitFor(DataGrid)} />
                                    <Route path="/Datatable" component={waitFor(Datatable)} />
                                    <Route path="/tablestandard" component={waitFor(TableStandard)} />
                                    {/* ------微信----- */}
                                    {/*NewsWechatHeader*/}
                                    {/* <Route path="/news/posts" component={waitFor(NewsPosts)} />
                                    <Route path="/news/header" component={waitFor(NewsWechatHeader)} />
                                    {/*NewsWechatFooter*/}
                                    {/* <Route path="/news/footer" component={waitFor(NewsWechatFooter)} /> */}
                                    {/*NewsWechatPublished*/}
                                    {/* <Route path="/news/published" component={waitFor(NewsWechatPublished)} /> */}
                                    {/*NewsWechatFound*/}
                                    {/* <Route path="/news/found" component={waitFor(NewsWechatFound)} /> */}
                                    {/*NewsWechatFound*/}
                                    {/* <Route path="/news/groups" component={waitFor(NewsWechatGroups)} /> */}
                                    {/*WechatRooms*/}
                                    {/* <Route path="/news/rooms" component={waitFor(WechatRooms)} /> */}
                                    {/*WechatRooms*/}
                                    {/* <Route path="/news/blacklists" component={waitFor(WechatBlacklists)} /> */}
                                    {/*WechatRooms*/}
                                    {/* <Route path="/news/members" component={waitFor(WechatMembers)} /> */}

                                    {/*WechatRooms*/}
                                    <Route path="/companies" component={waitFor(Companies)} />
                                    <Route path="/keywords" component={waitFor(Keywords)} />
                                    <Route path="/universities" component={waitFor(TopUniversities)} />
                                    <Route path="/educations" component={waitFor(Educations)} />
                                    <Route path="/positions" component={waitFor(Positions)} />

                                    <Route path="/fonticons" component={waitFor(FontIcons)} />
                                    {/* <Route path="/deals" component={waitFor(Deals)} /> */}
                                    <Route path="/companyinfos" component={waitFor(CompanyInfos)} />
                                    <Route path="/mysearches" component={waitFor(Mysearches)} />
                                    <Route path="/sharedwithme" component={waitFor(SharedwithMe)} />
                                    <Route path="/other" component={waitFor(Other)} />
                                    {/*WechatRooms*/}

                                    {/*自动回复*/}
                                    {/* <Route path="/wxautoreplys" component={waitFor(WechatAutoReplys)} /> */}
                                    {/*邀人入群*/}
                                    {/* <Route path="/news/addrooms" component={waitFor(NewswxAddRooms)} /> */}
                                    {/*新人入群*/}
                                    {/* <Route path="/news/welcome" component={waitFor(NewswxWelComes)} /> */}
                                    {/*禁用词*/}
                                    {/* <Route path="/news/bannedkeyords" component={waitFor(NewswxBannedKeyords)} /> */}
                                    {/*禁用词*/}
                                    {/* <Route path="/news/scheduler" component={waitFor(NewswxScheduler)} /> */}
                                    {/*踢人权限*/}
                                    {/* <Route path="/news/kickingauthority" component={waitFor(NewswxKickings)} /> */}
                                    {/*同义词*/}
                                    {/* <Route path="/termtags" component={waitFor(TermTags)} /> */}
                                    {/*行业词库/专业词汇*/}
                                    {/* <Route path="/terms" component={waitFor(Terms)} /> */}
                                    {/*非行业词库*/}
                                    {/* <Route path="/excludedterms" component={waitFor(ExcludedTerms)} /> */}
                                    {/*同义词*/}
                                    {/* <Route path="/synonyms" component={waitFor(Synonyms)} /> */}

                                    <Redirect to="/companies" />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);